import * as React from 'react';

function CloseIcon(props) {
  return (
    <svg
      width={26}
      height={29}
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_2_384)" fill="#FFC716">
        <path d="M8.588 22.171c1.725-2.654 3.454-5.304 5.18-7.957 1.515-2.327 3.24-4.615 4.492-7.1.23-.456-.439-.879-.669-.42-1.25 2.5-2.988 4.796-4.51 7.131-1.717 2.636-3.43 5.274-5.15 7.907-.28.429.378.869.657.439z" />
        <path d="M16.566.5C9.806-1.594 3.452 3.17 1.183 9.533c-2.076 5.825-1.82 14.37 4.037 17.88 12.184 7.298 27.159-12.37 17.9-22.878C18.3-.93 9.682-.324 5.351 5.44c-.304.404.259.96.569.552 3.541-4.66 10.3-6.043 15.044-2.403 5.47 4.2 5.263 11.37 1.782 16.814-3.314 5.187-9.379 9.346-15.619 7.044-6.22-2.294-7.154-10.131-5.737-15.85C3.057 4.873 9.182-.953 16.345 1.267c.484.15.706-.618.221-.767z" />
        <path d="M9.344 8.221a137.65 137.65 0 009.925 13.371c.329.39.947-.103.618-.495a138.828 138.828 0 01-9.853-13.259c-.278-.426-.97-.047-.69.383z" />
      </g>
      <defs>
        <clipPath id="clip0_2_384">
          <path fill="#fff" d="M0 0H26V29H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloseIcon;